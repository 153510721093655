import React from 'react';
import './keysWork.css';
import {Card, Col, Flex, Row} from "antd";
import {Fade} from "react-awesome-reveal";
import Carousel from "react-multi-carousel";
import header from '../../images/keys/sophistiquée/header.png';
import left_sophistiquée from '../../images/keys/sophistiquée/main_left_block.png';
import right_sophistiquée from '../../images/keys/sophistiquée/main_right_block.png';

import left_miami from '../../images/keys/miami/left_main.png';
import right_miami from '../../images/keys/miami/right_main.png';

import bg_nma from '../../images/keys/nma/bg_nma.png';

import ButtonGroup from "antd/es/button/button-group";

const KeysWork = (props) => {


    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
            partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 1,
            slidesToSlide: 1, // optional, default to 1.
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 ,// optional, default to 1.
            partialVisibilityGutter: 40
        }
    };





    return (
        <div className="container">
            <Flex vertical id="keys" data-cursor-exclusion>
                <div className="title_thour_section" style={{color:`${props.textColor}`,transition:'1s all ease'}}>
                    <Fade delay={0} cascade damping={0.05} style={{transition:'1s all ease'}}>
                        Наши кейсы
                    </Fade>
                </div>
                <Carousel
                    arrows={true}
                    swipeable={true}
                    draggable={true}
                    showDots={true}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5s"
                    transitionDuration={300}
                    containerClass="carousel-container"
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item"
                    partialVisible={true}
                    minimumTouchDrag={5}
                >
                    <Card style={{
                        margin: '0 20px 0 0',
                        position: 'relative', // Важно для позиционирования видео внутри карточки
                        overflow: 'hidden',
                        background: '#fff',// Чтобы видео не выходило за границы карточки
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                    }}
                    >
                        <div className="keys_sophistiquée">
                            <div className="header_keys_sophistiquée">
                                <img src={header} alt="Кейс sophistiquée"/>
                            </div>
                            <div className="title_keys_sophistiquée">
                                Sophistiquée Spaces
                            </div>
                            <Flex align="flex-start" className="body_keys_sophistiquée">
                                    <Flex vertical align="start" className="left_image_keys_sophistiquée_css">
                                        01
                                        <img src={left_sophistiquée} alt="Кейс sophistiquée"/>
                                    </Flex>
                                    <Flex  class="right_sophistiquée_css"  align="start" vertical className="body_right_keys_sophistiquée">
                                        <div className="right_keys_sophistiquée_title">
                                            СТУДИЯ ДИЗАЙНА ИНТЕРЬЕРОВ
                                            И АРХИТЕКТУРЫ
                                        </div>
                                        <Flex align="start" vertical className="right_keys_sophistiquée_title_image">
                                            02
                                            <img src={right_sophistiquée} alt="Кейс sophistiquée"/>
                                        </Flex>
                                        <Flex justify="space-between" className="descr_keys_sophistiquée_css">
                                            <Flex align="start" vertical className="desc_right_keys_sophistiquée">
                                                <div className="title_desc_right_keys_sophistiquée">
                                                    Софт минимализм
                                                </div>
                                                <div className="desc_title_desc_right_keys_sophistiquée">
                                                    квартира в Москве, 74 м²
                                                </div>
                                            </Flex>
                                            <div className="geo_name_right_keys_sophistiquée">
                                                Москва, 2024
                                            </div>
                                        </Flex>
                                    </Flex>
                            </Flex>
                        </div>

                    </Card>
                    <Card style={{
                        margin: '0 20px 0 0',
                        position: 'relative', // Важно для позиционирования видео внутри карточки
                        overflow: 'hidden',
                        background: '#fff',// Чтобы видео не выходило за границы карточки
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                    }}
                    >
                        <div className="keys_miami_house_rent">
                            <div className="header_miami_house_rent">
                                <Flex justify="space-between" align="flex-start">
                                    <Flex align="flex-start" vertical>
                                        <div className="logo_miami_house_rent">
                                            MiaMi
                                        </div>
                                        <div className="desc_logo_miami_house_rent">
                                            аренда домов
                                        </div>
                                    </Flex>
                                    <Flex gap={100}>
                                        <ul className="list_menu_miami_house_rent">
                                            <li>Дома</li>
                                            <li>Квартиры</li>
                                            <li>Клиенты</li>
                                        </ul>
                                        <ul className="list_menu_miami_house_rent">
                                            <li>О нас</li>
                                            <li>Партнёры</li>
                                            <li>Контакты</li>
                                        </ul>
                                        <ul className="list_menu_miami_house_rent">
                                            <li>Блог</li>
                                            <li>Галерея</li>
                                            <li className="auth_menu_miami_house_rent">Войти</li>
                                        </ul>
                                    </Flex>
                                </Flex>
                            </div>
                            <div className="title_miami_house_rent">
                                Miami house rent
                            </div>
                        </div>
                        <div className="body_miami_house_rent">
                            <Flex className="flex_two_block_body_miami_house_rent">
                                <Row align="flex-start" className="column_mobile_miami_body">
                                    <Col className="col_mobile_miami_body" span={14}>

                                        <Flex vertical className="name_city_miami_house_rent">
                                            <ul className="list_name_city_miami_house_rent">
                                                <li>
                                                <span className="number_name_city_miami_house_rent active">
                                                    01
                                                </span>
                                                    <span className="title_name_city_miami_house_rent">
                                                    Оушен-Драйв
                                                </span>
                                                </li>
                                                <li>
                                                <span className="number_name_city_miami_house_rent">
                                                    02
                                                </span>
                                                    <span className="title_name_city_miami_house_rent">
                                                    Район Бэйшор
                                                </span>
                                                </li>
                                                <li>
                                                <span className="number_name_city_miami_house_rent">
                                                    03
                                                </span>
                                                    <span className="title_name_city_miami_house_rent">
                                                    Саут-Бич
                                                </span>
                                                </li>
                                                <li>
                                                <span className="number_name_city_miami_house_rent">
                                                    04
                                                </span>
                                                    <span className="title_name_city_miami_house_rent">
                                                    Парк Саут-Пойнт
                                                </span>
                                                </li>
                                            </ul>
                                            <div className="image_list_name_city_miami_house_rent">
                                                <img src={left_miami} alt="Кейс MiaMi"/>
                                                <Flex align="center" justify="space-between" className="desc_image_miami_house_rent">
                                                    <div className="name_photo_and_price_miami_house_rent">
                                                        Оушен-Драйв / 3 760 000 $
                                                    </div>
                                                    <button className="btn_photo__miami_house_rent">
                                                        Смотреть дом
                                                    </button>
                                                </Flex>
                                            </div>
                                        </Flex>
                                    </Col>
                                    <Col className="col_mobile_miami_body" span={10}>
                                        <Flex vertical className="name_city_miami_house_rent w53_css">
                                            <ul className="list_name_city_miami_house_rent">
                                                <li>
                                                    На Оушен-драйв между Пятой и Пятнадцатой улицами расположен впечатляющий район ар-деко Множество небольших зданий в стиле ар-деко можно найти в районе Саут-Бич. Здесь расположены небольшие объекты недвижимости для круглогодичного проживания.
                                                </li>
                                            </ul>
                                            <div className="image_list_name_city_miami_house_rent">
                                                <img src={right_miami} alt="Кейс MiaMi"/>
                                            </div>
                                        </Flex>
                                    </Col>
                                </Row>
                            </Flex>
                        </div>

                    </Card>
                    <Card style={{
                        margin: '0 20px 0 0',
                        position: 'relative', // Важно для позиционирования видео внутри карточки
                        overflow: 'hidden',
                        background: '#fff',// Чтобы видео не выходило за границы карточки
                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                    }}
                    >
                        <div className="keys_nma_agency" style={{
                            backgroundImage:`url(${bg_nma})`,
                            backgroundRepeat: 'no-repeat',
                            backgroundSize: 'cover',
                            backgroundPosition: 'center center'
                        }}>
                            <Flex justify="space-between" gap={20} className="header_nma_agency">
                                <div className="logo_nma_agency">
                                    nma.agency
                                </div>
                                <Flex vertical align="flex-start" className="call_nma_agency">
                                    <span className="title_call_nma_agency">Менеджер</span>
                                    <span className="subtitle_call_nma_agency">+7 (000) 000-00-00</span>
                                </Flex>
                                <Flex vertical align="flex-start" className="call_nma_agency">
                                    <span className="title_call_nma_agency">Адрес</span>
                                    <span className="subtitle_call_nma_agency">Москва набережная, 15, стр. 12</span>
                                </Flex>
                                <svg xmlns="http://www.w3.org/2000/svg" width="30" height="31" viewBox="0 0 69 31" fill="none">
                                    <path d="M0.124298 2.99756V0.247559H68.8326V2.99756H0.124298ZM68.8326 13.9976V16.7476H0.124298V13.9976H68.8326ZM0.124298 27.7476H68.8326V30.4976H0.124298V27.7476Z" fill="white"/>
                                </svg>
                            </Flex>
                            <Row align="center">
                                <Col span={6}>
                                    <div className="title_row_nma_agnecy">
                                        NM
                                    </div>
                                </Col>
                                <Col span={12}>
                                    <div className="subtitle_row_nma_agnecy">
                                        Динамичное модельное агентство, расположенное в самом сердце Москвы. Мы предлагаем широкий спектр услуг в сфере модельного бизнеса
                                    </div>
                                </Col>
                                <Col span={6}>
                                    <div className="title_row_nma_agnecy">
                                        A
                                    </div>
                                </Col>
                            </Row>
                            <Row>
                                <Col span={24}>
                                    <div className="title_row_nma_agnecy title_row_nma_agnecy_end">
                                        AGENCY
                                    </div>
                                </Col>
                            </Row>
                            <Row className="padding_top_desc_nma">
                                <Col>
                                    <div className="bottom_desc_nma">
                                        <li>
                                            Профессионализм.
                                        </li>
                                        <li>
                                            Индивидуальный подход.
                                        </li>
                                        <li>
                                            Широкая база моделей.
                                        </li>
                                    </div>
                                </Col>
                            </Row>
                        </div>
                    </Card>
                </Carousel>



            </Flex>


        </div>

    );
};

export default KeysWork;