import figma_icon from '../../../../images/iconTechnology/figma_icon.svg'
import photoshop_icon from '../../../../images/iconTechnology/fotoshop_icon.svg'
import three_web_icon from '../../../../images/iconTechnology/three_webd_icon.svg'
import thour_web_icon from '../../../../images/iconTechnology/thour_web_icon.svg'
import illustrator_icon from '../../../../images/iconTechnology/illustrator_icon.svg'
import after_effect_icon from '../../../../images/iconTechnology/effect_icon.svg'
import blender_icon from '../../../../images/iconTechnology/blender_icon.svg'

const list_web_design = [
    {
        name: 'Figma',
        image: figma_icon,
        id: 'wd1'
    },
    {
        name: 'Adobe Photoshop',
        image: photoshop_icon,
        id: 'wd2'
    },
    {
        name: 'Illustrator',
        image: illustrator_icon,
        id: 'wd5'
    },
    {
        name: 'After Effect',
        image: after_effect_icon,
        id: 'wd6'
    },
]

export default list_web_design;