import React, {useState} from 'react';
import {Cursor} from "react-creative-cursor";
import {Button, Checkbox, Flex, Form, Input, message, Modal} from "antd";
import ModalChangePassword from "../modal_change password/modal_change_password";
import {useNavigate} from "react-router-dom";
import axios from "axios";
import {CloseOutlined} from "@ant-design/icons";

const ModalProfile = (props) => {

    const [form] = Form.useForm();

    const handleOkAuth = () => {
        props.setIsModalProfileOpen(true);
    };

    const handleCancelAuth = () => {
        props.setIsModalProfileOpen(false);
    };

    const [isPasswordModalVisible, setIsPasswordModalVisible] = useState(false);





    const onFinish = async (values) => {

        try {
            await axios.post(`${props.devUrl}/api/auth/update`, values, {
                headers: {
                    'Authorization': `Bearer ${localStorage.getItem('token')}`
                }
            });
            message.success('Информация успешно обновлена!');
        } catch (error) {
            if (error.response && error.response.status === 400) {
                const errorMessage = error.response.data.errors.map(err => err.msg).join(', ');
                message.error(`Ошибка валидации: ${errorMessage}`);
            } else {
                message.error('Ошибка при обновлении информации.');
            }
            console.error('Ошибка:', error);
        }
        handleCancelAuth();
    };

    const handlePasswordChange = async (values) => {
        console.log('Password change values:', values);
        // Здесь можно отправить запрос на сервер для смены пароля
        message.success('Пароль успешно изменен!');
        setIsPasswordModalVisible(false);
    };


    return (
        <>
            <Modal
                style={{background:`${props.bgColor}`, paddingBottom:'0', color:'#fff', borderRadius:'20px'}}
                open={props.isModalProfileOpen}
                onOk={handleOkAuth}
                data-cursor-exclusion
                closeIcon={<CloseOutlined style={{color:`${props.textColor}`}} />}
                title={<p style={{
                    background:`${props.bgColor}`,
                    color:`${props.textColor}`
                }}>Профиль</p>}
                onCancel={handleCancelAuth}
                footer={null}
            >
                <Cursor
                    cursorSize={20}
                    animationDuration={.3}
                    cursorBackgrounColor={'#000'}
                    isGelly={true}
                    gellyAnimationAmount={10}
                />
                <Form
                    form={form}
                    name="user_info"
                    onFinish={onFinish}
                    initialValues={{
                        remember: true,
                    }}
                >
                    <Form.Item
                        name="phone"
                        label={<p style={{color: `${props.textColor}`}}>Номер телефона</p>}
                        rules={[
                            {
                                required: true,
                                message: 'Пожалуйста, введите номер телефона!',
                            },
                        ]}
                    >
                        <Input placeholder={props.phoneUser}/>
                    </Form.Item>
                    <Form.Item
                        name="backupEmail"
                        label={<p style={{color: `${props.textColor}`}}>Резервный email</p>}
                        rules={[
                            {
                                whitespace: true,
                            },
                            {
                                validator: (_, value) =>
                                    !value || /\S+@\S+\.\S+/.test(value)
                                        ? Promise.resolve()
                                        : Promise.reject('Некорректный адрес электронной почты!'),
                            },
                        ]}
                    >
                        <Input placeholder={props.backupMail}/>
                    </Form.Item>
                    <Form.Item
                        name="nameOrganisation"
                        label={<p style={{color: `${props.textColor}`}}>Название компании</p>}
                    >
                        <Input placeholder={props.nameOrganisation}/>
                    </Form.Item>
                    <Form.Item>
                        <Button
                            style={{color:`${props.textColor === '#fff' ? '#000' : '#fff'}`,
                                background:`${props.bgColor === '#fff' ? '#292929' : '#fff'}`}}
                            htmlType="submit">
                            Сохранить
                        </Button>
                        <Button
                            type="link"
                            onClick={() => {
                                setIsPasswordModalVisible(true);
                                props.setIsModalProfileOpen(false);
                            }}
                            style={{color:`${props.textColor}`}}
                        >
                            Сменить пароль
                        </Button>
                    </Form.Item>
                </Form>

            </Modal>
            <ModalChangePassword
                bgColor={props.bgColor}
                textColor={props.textColor}
                isPasswordModalVisible={isPasswordModalVisible}
                setIsPasswordModalVisible={setIsPasswordModalVisible}
            />

        </>





    );
};

export default ModalProfile;