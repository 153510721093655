import React from 'react';
import './Footer.css';


const Footer = (props) => {



    return (
        <>
            <footer data-cursor-exclusion style={{background:`${props.bgColor}`}}>
                <p style={{color:`${props.textColor}`}}>© 2024 IT Prodigy</p>
            </footer>
        </>

    );
};

export default Footer;