import React, {useEffect, useState} from 'react';
import HoverVideoPlayer from "react-hover-video-player";
import video from "../../../../videos/web_development.mp4";
import './card.css'

const CardBottom = (props) => {
    const changeHoverLink = () => {
        props.setCursorUslugi('#292929')
    }
    const changeHoverLinkOut = () => {
        props.setCursorUslugi("#fff")
    }

    const [isMobile, setIsMobile] = useState(window.innerWidth < 768);
    const [isPlaying, setIsPlaying] = useState(false);

    useEffect(() => {
        const handleResize = () => {
            setIsMobile(window.innerWidth < 768);
        };
        window.addEventListener('resize', handleResize);

        return () => window.removeEventListener('resize', handleResize);
    }, []);

    const handleClick = () => {
        if (isMobile) {
            setIsPlaying(!isPlaying);
        }
    };

    return (
        <div className='card_uslugi_section_three' >


            {props.cardsBottom.map(el => (
                <div key={el.id} className='one_usluga_section__three' onClick={isMobile ? handleClick : null}>
                    <HoverVideoPlayer
                        onMouseEnter={!isMobile ? () => setIsPlaying(true) : null}
                        onMouseLeave={!isMobile ? () => setIsPlaying(false) : null}
                        videoSrc={el.video}
                        pausedOverlay={
                            <div className="border_for_usligi" style={{height:'500px',padding:'20px',backgroundColor:props.bgColor,transition:'1s all ease'}}>
                                <div className="name_card_uslugi_section_three" style={{color:props.textColor,transition:'1s all ease'}}>
                                    {el.name}
                                </div>
                                <div className="desc_card_uslugi_section_three" style={{color:props.textColor,transition:'1s all ease'}}>
                                    {el.description}
                                </div>
                            </div>
                        }
                        playbackState={isPlaying ? "playing" : "paused"}
                    />
                </div>
            ))}
        </div>
    );
};

export default CardBottom;