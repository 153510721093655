import React, {useState} from 'react';
import {Modal} from "antd";
import { Button, Form, Input, Space } from 'antd';
import {Cursor} from "react-creative-cursor";
import './modal_send.css';
import {CloseOutlined} from "@ant-design/icons";
import axios from "axios";
import { Spin } from 'antd';
const ModalSend = (props) => {




    const [isPopupVisible, setIsPopupVisible] = React.useState(false);  //  для появления попапа об успешной отправке
    const [isPopupFading, setIsPopupFading] = React.useState(false); //  для плавного исчезновения

    const [isLoadingForm,SetIsLoadingForm] = useState(false)




    const SubmitButton = ({ form, children, textColor, bgColor }) => {
        const [submittable, setSubmittable] = React.useState(false);

        const values = Form.useWatch([], form);

        React.useEffect(() => {
            form
                .validateFields({ validateOnly: true })
                .then(() => setSubmittable(true))
                .catch(() => setSubmittable(false));
        }, [form, values]);

        return (
            <Button
                className="btn_offer"
                style={{
                    color: textColor === '#fff' ? '#fff' : '#292929',
                    background: bgColor === '#fff' ? '#292929' : '#fff'
                }}
                htmlType="submit" // Убедитесь, что это тип submit
                disabled={!submittable}
            >
                {children}
            </Button>
        );
    };


    const handleOk = () => {
        props.setIsModalOpen(false);
    };

    const handleCancel = () => {
        props.setIsModalOpen(false);
    };
    const [form] = Form.useForm();


    const handleSubmitSend = async (values) => {

        SetIsLoadingForm(true); // Включаем крутилку


        try {
            const response = await axios.post(`${props.devUrl}/api/auth/submit-form`,
                values, // убрали JSON.stringify
                {
                    headers: {
                        'Content-Type': 'application/json'
                    }
                }
            );

            if (response.status === 200) {
                props.setIsModalOpen(false); // Закрытие модального окна
                setIsPopupVisible(true); // Отображение попапа


                form.resetFields();

                // Через 3 секунды начнем анимацию исчезновения попапа
                setTimeout(() => setIsPopupFading(true), 1500);

                // Полностью скрыть попап после анимации (3.5 секунд)
                setTimeout(() => {
                    setIsPopupVisible(false);
                    setIsPopupFading(false); // Сброс для следующего отображения
                }, 2000);
            } else {
                console.error('Ошибка при отправке заявки');
                alert('Ошибка при отправке заявки');
            }
        } catch (error) {
            console.error('Ошибка:', error);
            alert('Произошла ошибка при отправке');
        } finally {
            SetIsLoadingForm(false); // Отключаем крутилку после завершения запроса
        }
    };





    return (
        <>
            <Modal data-cursor-exclusion
                   title={<p style={{
                       background:`${props.bgColor}`,
                       color:`${props.textColor}`
                   }}>Оставьте заявку</p>}
                   style={{background:`${props.bgColor}`, paddingBottom:'0', color:'#fff', borderRadius:'20px'}}
                   open={props.isModalOpen}
                   closeIcon={<CloseOutlined style={{color:`${props.textColor}`}} />}
                   onOk={handleOk}
                   onCancel={handleCancel}

            >
                <Cursor
                    cursorSize={20}
                    animationDuration={.3}
                    cursorBackgrounColor={'#000'}
                    isGelly={true}
                    gellyAnimationAmount={10}
                />
                <Form
                    onFinish={handleSubmitSend}
                    form={form}
                    name="validateOnly"
                    layout="vertical"
                    autoComplete="off">
                    <Form.Item
                        name="name"
                        label={<p style={{color: `${props.textColor}`}}>Ваше имя</p>}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item
                        name="email"
                        label={<p style={{color: `${props.textColor}`}}>E-mail</p>}
                        rules={[
                            {
                                required: true,
                            },
                        ]}
                    >
                        <Input />
                    </Form.Item>
                    <Form.Item name='message'
                               label={<p style={{color: `${props.textColor}`}}>Введите ваше сообщение</p>}
                    >
                        <Input.TextArea />
                    </Form.Item>
                    <Form.Item>
                        <Space>
                            <SubmitButton
                                style={{color:`${props.textColor === '#fff' ? '#000' : '#000'}`}}
                                form={form}>Отправить
                                {/* Отображаем крутилку, если isLoading равно true */}
                                {isLoadingForm && (
                                    <div className="loading-spinner">
                                        <Spin size="small" />
                                    </div>
                                )}
                            </SubmitButton>
                            <Button htmlType="reset">Очистить</Button>
                        </Space>
                    </Form.Item>
                </Form>
            </Modal>

            {/* Попап для уведомления об успешной отправке */}
            {isPopupVisible && (

                <>
                    <div style={{
                        position: 'fixed',
                        top: 0,
                        left: 0,
                        width: '100%',
                        height: '100%',
                        backgroundColor: 'rgba(0, 0, 0, 0.5)', // Полупрозрачный черный фон
                        zIndex: 999 // Задает, что затемнение будет позади попапа
                    }}>

                    </div>

                    <div
                        className="popup_success"
                        style={{
                            position: 'fixed',
                            top:'50%',
                            left: '50%',
                            transform:'translate(-50%,0)',
                            background: `${props.bgColor}`,
                            color: `${props.textColor}`,
                            padding: '10px 20px',
                            borderRadius: '8px',
                            boxShadow: '0 4px 8px rgba(0, 0, 0, 0.2)',
                            zIndex:9999,
                            opacity: isPopupFading ? 0 : 1, // Уменьшаем прозрачность для плавного исчезновения
                            transition: 'opacity 0.5s ease', // Плавный переход
                        }}>
                        Сообщение успешно отправлено!
                    </div>
                </>

            )}
        </>
    );
};

export default ModalSend;