import React from 'react';
import {Form, Modal, Input, Button} from "antd";
import {CloseOutlined} from "@ant-design/icons";

const ModalChangePassword = (props) => {

    const handlePasswordChange = async (values) => {
        console.log('Password change values:', values);


        props.setIsPasswordModalVisible(false);
    };

    return (
        <Modal
            style={{background:`${props.bgColor}`, paddingBottom:'0', color:'#fff', borderRadius:'20px'}}
            visible={props.isPasswordModalVisible}
            closeIcon={<CloseOutlined style={{color:`${props.textColor}`}} />}
            title={<p style={{
                background:`${props.bgColor}`,
                color:`${props.textColor}`
            }}>Смена пароля</p>}
            onCancel={() => props.setIsPasswordModalVisible(false)}
            footer={null}
        >
            <Form
                name="password_change"
                onFinish={handlePasswordChange}
            >
                <Form.Item
                    name="oldPassword"
                    label={<p style={{color: `${props.textColor}`}}>Старый пароль</p>}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите старый пароль!',
                        },
                    ]}
                >
                    <Input.Password />

                </Form.Item>
                <Form.Item
                    name="newPassword"
                    label={<p style={{color: `${props.textColor}`}}>Новый пароль</p>}
                    rules={[
                        {
                            required: true,
                            message: 'Пожалуйста, введите новый пароль!',
                        },
                    ]}
                >
                    <Input.Password />
                </Form.Item>
                <Form.Item>
                    <Button
                        style={{color:`${props.textColor === '#fff' ? '#000' : '#fff'}`,
                            background:`${props.bgColor === '#fff' ? '#292929' : '#fff'}`}}
                        htmlType="submit">
                        Сменить пароль
                    </Button>
                </Form.Item>
            </Form>
        </Modal>
    );
};

export default ModalChangePassword;