import React, {useRef, useState} from 'react';
import {Fade, Slide} from "react-awesome-reveal";
import 'react-creative-cursor/dist/styles.css';
import './section1.css';
import {CheckCircleFilled} from "@ant-design/icons";
import { gsap } from 'gsap';
import AnimatedDots from "../animatedDots";
import ModalSend from "../../modal_send/modal_send";






const Section1 = (props) => {




    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);

    };



    return (
        <>


            <div id="main" className="block_hello_main" data-cursor-exclusion style={{ position: "relative" }}>
                <div>
                    <>
                        <div className="hello_text mb100">
                            <Fade delay={0} cascade damping={0.05} style={{color:`${props.textColor}`}}>
                                IT Prodigy
                            </Fade>
                            <Fade delay={300} cascade damping={0.05} style={{color:`${props.textColor}`}}>
                                Web development studio!
                            </Fade>
                        </div>
                        <Slide duration={1000} delay={200}>
                            <div
                                className="description_text"
                                style={{
                                    color:`${props.textColor}`,
                                    padding: "15px 0",
                                }}
                            >
                                <CheckCircleFilled /> Разработка сайтов и веб-дизайн под ключ от 30 000 рублей.
                            </div>
                        </Slide>
                        <Slide duration={1000} delay={200}>
                            <div
                                className="description_text"
                                style={{
                                    color:`${props.textColor}`,
                                    padding: "15px 0",
                                }}
                            >
                                <CheckCircleFilled /> Наши сайты — это уникальные проекты с индивидуальным дизайном, созданные без использования шаблонов и конструкторов. Каждый элемент кода пишется вручную, что позволяет реализовать самые оригинальные идеи и функции. Мы создаем сайты, которые выделяются и идеально подходят под задачи вашего бизнеса.
                            </div>
                        </Slide>

                            <div className="description_text">
                                <ul style={{display:"flex",flexWrap:'wrap',justifyContent:'center',alignItems:'center'}}>
                                    <Fade>
                                        <li className="list_main_three" style={{
                                            color: `${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                                            border: `1px solid ${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                                        }}>
                                            современные и адаптивные решения
                                        </li>
                                    </Fade>
                                    <Fade>
                                        <li className="list_main_three" style={{
                                            color: `${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                                            border: `1px solid ${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                                        }}>
                                            быстрый запуск и эффективная работа
                                        </li>
                                    </Fade>
                                    <Fade>
                                        <li className="list_main_three" style={{
                                            color: `${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                                            border: `1px solid ${props.bgColor === '#fff' ? '#292929' : '#fff'}`,

                                        }}>
                                            подход, ориентированный на рост вашего бизнеса
                                        </li>
                                    </Fade>
                                </ul>
                            </div>
                            <button
                                className="description_text btn_order_main"
                                style={{
                                    padding: "10px 20px",
                                    borderRadius:'20px',
                                    border:'none',
                                    display:'flex',
                                    marginTop:'20px',
                                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                                    color:`${props.textColor}`,
                                    background:'transparent'
                                }}
                                onClick={showModal}
                            >
                                Обсудить проект
                            </button>

                        {/*<Slide duration={1000} delay={200}>*/}
                        {/*    <div*/}
                        {/*        className="description_text"*/}
                        {/*        style={{*/}
                        {/*            color: `${props.textColor}`,*/}
                        {/*            background: `${props.bgColor}`,*/}
                        {/*            padding: "15px",*/}
                        {/*            borderRadius: "20px",*/}
                        {/*            marginTop: "20px",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                <CheckCircleFilled /> Современные, адаптивные решения*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</Slide>*/}
                        {/*<Slide duration={1000} delay={200}>*/}
                        {/*    <div*/}
                        {/*        className="description_text"*/}
                        {/*        style={{*/}
                        {/*            color: `${props.textColor}`,*/}
                        {/*            background: `${props.bgColor}`,*/}
                        {/*            padding: "15px",*/}
                        {/*            borderRadius: "20px",*/}
                        {/*            marginTop: "20px",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                <CheckCircleFilled /> Быстрый запуск и эффективная работа*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</Slide>*/}
                        {/*<Slide duration={1000} delay={200}>*/}
                        {/*    <div*/}
                        {/*        className="description_text"*/}
                        {/*        style={{*/}
                        {/*            color: `${props.textColor}`,*/}
                        {/*            background: `${props.bgColor}`,*/}
                        {/*            padding: "15px",*/}
                        {/*            borderRadius: "20px",*/}
                        {/*            marginTop: "20px",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                <CheckCircleFilled /> Подход, ориентированный на рост вашего бизнеса*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</Slide>*/}
                        {/*<Slide duration={1000} delay={200}>*/}
                        {/*    <div*/}
                        {/*        className="description_text"*/}
                        {/*        style={{*/}
                        {/*            color: `${props.textColor}`,*/}
                        {/*            background: `${props.bgColor}`,*/}
                        {/*            padding: "15px",*/}
                        {/*            borderRadius: "20px",*/}
                        {/*            marginTop: "20px",*/}
                        {/*        }}*/}
                        {/*    >*/}
                        {/*        <ul>*/}
                        {/*            <li>*/}
                        {/*                <CheckCircleFilled /> Начните проект уже сегодня! Мы воплотим ваши идеи в реальность.*/}
                        {/*            </li>*/}
                        {/*        </ul>*/}
                        {/*    </div>*/}
                        {/*</Slide>*/}
                    </>
                </div>
                {/* AnimatedDots размещается в этом контейнере */}
                {/*<div>*/}
                {/*    <AnimatedDots devUrl={props.devUrl} bgColor={props.bgColor} textColor={props.textColor} />*/}
                {/*</div>*/}

            </div>



            {/*<div className="block_parallax">*/}
            {/*    <Fade duration={3000}>*/}
            {/*        <div className="image_nout">*/}
            {/*            <img src={nout} alt=""/>*/}
            {/*        </div>*/}
            {/*    </Fade>*/}
            {/*    <Fade delay={500} duration={3000}>*/}
            {/*        <div className="image_cube">*/}
            {/*            <img src={cube} alt=""/>*/}
            {/*        </div>*/}
            {/*    </Fade>*/}
            {/*    <Fade delay={1000} duration={3000}>*/}
            {/*        <div className="image_coffee">*/}
            {/*            <img src={coffe} alt=""/>*/}
            {/*        </div>*/}
            {/*    </Fade>*/}
            {/*</div>*/}
            <ModalSend
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                bgColor={props.bgColor}
                textColor={props.textColor}
                devUrl={props.devUrl}
            />
        </>

    );
};

export default Section1;