import React, {useState} from 'react';

import {Card, Flex} from "antd";
import {Fade} from "react-awesome-reveal";
import Carousel from 'react-multi-carousel';
import 'react-multi-carousel/lib/styles.css';





const Section3Mobile = (props) => {

    const responsive = {
        desktop: {
            breakpoint: { max: 3000, min: 1024 },
            items: 3,
            slidesToSlide: 3, // optional, default to 1.
            partialVisibilityGutter: 40
        },
        tablet: {
            breakpoint: { max: 1024, min: 464 },
            items: 2,
            slidesToSlide: 2, // optional, default to 1.
            partialVisibilityGutter: 40
        },
        mobile: {
            breakpoint: { max: 464, min: 0 },
            items: 1,
            slidesToSlide: 1 ,// optional, default to 1.
            partialVisibilityGutter: 40
        }
    };



    const [showWebDes, setShowWebDesign] = useState(false);
    const [showWebDev, setShowWebDev] = useState(true);

    return (
        <div className="container">
            <Flex gap={40} id="services" className="services_mobile" data-cursor-exclusion>
                <div className="title_three_section" style={{color:`${props.textColor}`,transition:'1s all ease'}}>
                    <Fade delay={0} cascade damping={0.05} style={{transition:'1s all ease'}}>
                        Услуги, которые мы предоставляем
                    </Fade>
                </div>
                <div className="wrapper_btn_services">
                    <button
                        style={{
                            opacity:`${showWebDev === true ? '1' : '.7'}`,
                            color:`${props.textColor}`,
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                            background:'transparent'
                        }}
                        onClick={() => {
                            setShowWebDesign(false);
                            setShowWebDev(true);
                        }}
                    >Разработка</button>
                    <button
                        style={{
                            opacity:`${showWebDes === true ? '1' : '.7'}`,
                            boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px',
                            color:`${props.textColor}`,
                            background:'transparent'
                        }}
                        onClick={() => {
                            setShowWebDesign(true);
                            setShowWebDev(false);
                        }}
                    >Веб-дизайн</button>
                </div>
                {showWebDev &&
                <Carousel
                    swipeable={true}
                    draggable={true}
                    showDots={false}
                    responsive={responsive}
                    ssr={true} // means to render carousel on server-side.
                    infinite={true}
                    autoPlaySpeed={1000}
                    keyBoardControl={true}
                    customTransition="all .5s"
                    transitionDuration={300}
                    containerClass="carousel-container"
                    removeArrowOnDeviceType={["tablet", "mobile"]}
                    dotListClass="custom-dot-list-style"
                    itemClass="carousel-item-margin"
                    partialVisible={true}
                    minimumTouchDrag={5}
                >



                            {props.cards.map(el => (
                                <Card style={{
                                    margin: '0 20px 0 0',
                                        position: 'relative', // Важно для позиционирования видео внутри карточки
                                        overflow: 'hidden',
                                        background: '#fff',// Чтобы видео не выходило за границы карточки
                                        boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                                    }}
                                    hoverable
                                >
                                    <video
                                        autoPlay
                                        muted
                                        loop
                                        playsInline // Важно для мобильных устройств, чтобы не открывать на весь экран
                                        style={{
                                            position: "absolute",
                                            top: 0,
                                            left: 0,
                                            width: "100%",
                                            height: "40%",
                                            objectFit: "cover", // Заполнить карточку, сохраняя пропорции видео
                                            zIndex: 0,
                                            opacity: 1 // Разместить видео позади содержимого карточки
                                        }}
                                    >
                                        <source src={el.video} type="video/mp4" />
                                    </video>
                                    <div className="card_uslug_mobile">
                                        <div className="titleCardMobile"
                                             style={{
                                                 color: '#292929'
                                             }}
                                        >
                                            {el.name}
                                        </div>
                                        <div className="descCardMobile"
                                             style={{
                                                 color: '#292929'
                                             }}
                                        >
                                            {el.description}
                                        </div>
                                    </div>
                                </Card>
                            ))}


                </Carousel>
                }
                {showWebDes &&
                    <Carousel
                        swipeable={true}
                        draggable={true}
                        showDots={false}
                        responsive={responsive}
                        ssr={true} // means to render carousel on server-side.
                        infinite={true}
                        autoPlaySpeed={1000}
                        keyBoardControl={true}
                        customTransition="all .5s"
                        transitionDuration={300}
                        containerClass="carousel-container"
                        removeArrowOnDeviceType={["tablet", "mobile"]}
                        dotListClass="custom-dot-list-style"
                        itemClass="carousel-item-margin"
                        partialVisible={true}
                        minimumTouchDrag={5}
                    >
                        {props.cardsBottom.map(el => (
                            <Card
                                style={{
                                    margin: '0 20px 0 0',
                                    position: 'relative', // Важно для позиционирования видео внутри карточки
                                    overflow: 'hidden',// Чтобы видео не выходило за границы карточки
                                    background: '#fff',// Чтобы видео не выходило за границы карточки
                                    boxShadow: 'rgba(99, 99, 99, 0.2) 0px 2px 8px 0px'
                                }}
                                hoverable
                            >
                                <video
                                    autoPlay
                                    muted
                                    loop
                                    playsInline // Важно для мобильных устройств, чтобы не открывать на весь экран
                                    style={{
                                        position: "absolute",
                                        top: 0,
                                        left: 0,
                                        width: "100%",
                                        height: "40%",
                                        objectFit: "cover", // Заполнить карточку, сохраняя пропорции видео
                                        zIndex: 0,
                                        opacity: 1 // Разместить видео позади содержимого карточки
                                    }}
                                >
                                    <source src={el.video} type="video/mp4" />
                                </video>
                                <div className="card_uslug_mobile">
                                    <div className="titleCardMobile"
                                         style={{
                                             color: '#292929'
                                         }}
                                    >
                                        {el.name}
                                    </div>
                                    <div className="descCardMobile"
                                         style={{
                                             color: '#292929'
                                         }}
                                    >
                                        {el.description}
                                    </div>
                                </div>
                            </Card>
                        ))}

                    </Carousel>
                }


                <span className="price_calc_text"
                      style={{
                          color:`${props.textColor}`
                      }}
                >
                Цены в зависимости от сложности могут варьироваться, пожалуйста, оставьте заявку и мы рассчитаем стоимость Вашего проекта.
            </span>
            </Flex>


        </div>

    );
};

export default Section3Mobile;