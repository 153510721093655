import React, {useState} from 'react';
import {
    BgColorsOutlined,
    CommentOutlined,
    CustomerServiceOutlined,
    EllipsisOutlined, FontColorsOutlined,
    QuestionCircleOutlined
} from "@ant-design/icons";
import {Button, ColorPicker, FloatButton, Space} from "antd";

const BtnButton = (props) => {





    return (
        <>

            <FloatButton.Group
                trigger="click"
                style={{
                    insetInlineEnd: 24,
                    background: 'transparent',
                    zIndex:'9999'
                }}
                icon={<EllipsisOutlined />}
            >


                <FloatButton
                    trigger="click"
                    type=""
                    style={{
                        insetInlineEnd:124,
                    }}
                    onClick={() => {
                        props.lightTheme()
                    }}
                    icon={<BgColorsOutlined  />}
                >

                </FloatButton>

                <FloatButton  onClick={props.showModal} icon={<CommentOutlined />} />

            </FloatButton.Group>
        </>
    );
};

export default BtnButton;