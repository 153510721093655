import React from 'react';
import {Col, Row} from "antd";

const CompListWebDesign = (props) => {
    console.log(props.list_web_design)
    return (
        <div className='list_web'>
            <div className="block_one_list__technology_new">
                <div className="title_block_one_list__technology_new">
                    Веб дизайн
                </div>
                <div className="list_technology_new">
                    {props.list_web_design.map(el => (
                        <div className="link_technoology_new">
                            <img src={el.image} alt={el.image}/>
                            {el.name}
                        </div>
                    ))}
                </div>
            </div>
        </div>
    );
};

export default CompListWebDesign;