import React, {useState} from 'react';
import './home.css';

import Section1 from "./section1/section1";
import Section3 from "./section3/section3";
import {Cursor} from "react-creative-cursor";
import Section4 from "./section4/section4";
import Portfolio from "./porfolio/portfolio";
import bgMain from '../../images/home/main_bg_2.jpg'
import Section3Mobile from "./section3/section3_mobile";


import cardWebDevCorp from "../../videos/1.mp4";
import cardCreateLanding from "../../videos/2.mp4";
import cardCreateBlog from "../../videos/3.mp4";

import cardPrototips from "../../videos/prototips.mp4";
import cardUnicalDesign from "../../videos/unical_design.mp4";
import cardRedesign from "../../videos/redesign.mp4";
import cardUxUi from "../../videos/ux_ui.mp4";
import KeysWork from "../keysWork/keysWork";

const Home = (props) => {



    const cards = [
        {
            name: 'Создание корпоративных сайтов',
            description: 'Разработка сайтов для компаний, представляющих их услуги и продукты.',
            id: 'card1',
            video: cardWebDevCorp,
        },
        {
            name: 'Создание лендингов',
            description: 'Разработка одностраничных сайтов для продвижения конкретного продукта или услуги.',
            id: 'card2',
            video: cardCreateLanding,
        },
        {
            name: 'Создание блогов',
            description: 'Разработка персональных или корпоративных блогов с удобной системой управления контентом.',
            id: 'card3',
            video: cardCreateBlog,
        },
        {
            name: 'Сайты-визитки',
            description: 'Простые сайты с базовой информацией о компании или человеке.',
            id: 'card4',
            video: cardWebDevCorp,
        },
    ]
    const cardsBottom = [
        {
            name: 'Разработка уникального дизайна',
            description: 'Создание индивидуальных дизайнов для сайтов, соответствующих бренду и целям клиента.',
            id: 'card5',
            video: cardUnicalDesign,
        },
        {
            name: 'Редизайн сайтов',
            description: 'Обновление внешнего вида и функционала существующих сайтов.',
            id: 'card6',
            video: cardRedesign,
        },
        {
            name: 'UI/UX-дизайн',
            description: 'Разработка интерфейсов, ориентированных на удобство использования и положительный пользовательский опыт.',
            id: 'card7',
            video: cardUxUi,
        },
        {
            name: 'Создание прототипов',
            description: 'Разработка интерактивных прототипов для визуализации структуры и функционала сайта до его полной реализации.',
            id: 'card8',
            video: cardPrototips,
        },
    ]

    const [changeCursorUslugi, setCursorUslugi] = useState("#fff") //  смена курсора при наведении на услуги





    return (




        <>
            <Cursor
                cursorSize={changeCursorUslugi}
                animationDuration={.3}
                cursorBackgrounColor={'#fff'}
                isGelly={true}
                gellyAnimationAmount={10}
            />

                <div style={{
                    height: '100%',
                    position:'relative',
                    zIndex: 1,
                    backgroundPosition:'center center',

                }} className="home h100_section_one bg_image_main" data-cursor-exclusion>
                    {/*<video style={{position:'absolute',left:'-500px'}} controls autoPlay loop muted>*/}
                    {/*    <source src={video} type="video/mp4" >*/}
                    {/*    </source>*/}
                    {/*</video>*/}
                    <div className="area">
                        <ul className="circles">
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                            <li style={{background: `${props.bgColor === '#fff' ? '#f1f1f1'  : '#313131'}`}}></li>
                        </ul>
                    </div>
                    <div className="container" style={{width:'100%',
                    height:'70%'}}>
                        <Section1
                            bgColor={props.bgColor}
                            textColor={props.textColor}
                            devUrl={props.devUrl}
                        />
                    </div>
                </div>
                {/*<div className="home h_section_two">*/}
                {/*    <Section2 />*/}
                {/*</div>*/}
                <div className="home">
                    <Section3
                        bgColor={props.bgColor}
                        textColor={props.textColor}
                        setCursorUslugi={setCursorUslugi}
                        cardsBottom={cardsBottom}
                        cards={cards}
                    />
                </div>
                <div className="home">
                    <Section3Mobile
                        bgColor={props.bgColor}
                        textColor={props.textColor}
                        setCursorUslugi={setCursorUslugi}
                        cardsBottom={cardsBottom}
                        cards={cards}
                    />
                </div>
                <div className="home">
                    <Section4
                        bgColor={props.bgColor}
                        textColor={props.textColor}
                        setCursorUslugi={setCursorUslugi}/>
                </div>
                <div className="home">
                    <Portfolio
                        bgColor={props.bgColor}
                        textColor={props.textColor}
                        setCursorUslugi={setCursorUslugi}
                    />
                </div>
                <div className="home">
                    <KeysWork
                        bgColor={props.bgColor}
                        textColor={props.textColor}
                        setCursorUslugi={setCursorUslugi}
                    />
                </div>







        </>

    );
};

export default Home;