import React from 'react';
import {Fade} from "react-awesome-reveal";
import './section4.css'
import weblist from "./weblist";
import list_web_design from "./card_web_design/list_web_design";
import ListWebTechnology from "./card_web_technology/list_web_technology";
import CompListWebDesign from "./card_web_design/comp_list_web_design";
import {Typography} from "antd";
import {LikeOutlined, SafetyOutlined, TeamOutlined} from "@ant-design/icons";
const { Text } = Typography;

const Section4 = (props) => {




    return (
        <div id='about' className="block_thour_section" data-cursor-exclusion >
            <div className="container">
                <div className="title_thour_section" style={{color:`${props.textColor}`,transition:'1s all ease'}}>
                    <Fade delay={0} cascade damping={0.05} style={{transition:'.5s all ease'}}>
                        Почему выбирают нас
                    </Fade>
                    <ul className="block_advantages">
                        <li><LikeOutlined style={{padding:'10px',fontSize:'20px', marginRight:'10px',borderRadius:'50%',border:'1px solid #292929'}} /> Клиентоориентированный подход</li>
                        <li><SafetyOutlined style={{padding:'10px',fontSize:'20px', marginRight:'10px',borderRadius:'50%',border:'1px solid #292929'}} /> Прозрачность и честность</li>
                        <li><TeamOutlined style={{padding:'10px',fontSize:'20px', marginRight:'10px',borderRadius:'50%',border:'1px solid #292929'}} /> Поддержка</li>
                    </ul>
                </div>
            </div>
            <div className="container">
                <div className="title_block__two" >
                    <p className="instruments_title">
                        <Fade style={{color:`${props.textColor}`}}
                            cascade damping={0.02} delay={600}>
                            Инструменты которые мы используем
                        </Fade>
                    </p>
                </div>
                <div className="block_web_technology">
                    <Fade duration={1000}>
                        <ListWebTechnology
                            bgColor={props.bgColor}
                            textColor={props.textColor}
                            weblist={weblist}/>
                    </Fade>
                    <Fade duration={1000} delay={300}>
                        <CompListWebDesign
                            bgColor={props.bgColor}
                            textColor={props.textColor}
                            list_web_design={list_web_design}/>
                    </Fade>
                </div>
            </div>
        </div>
    );
};

export default Section4;