import React from 'react';
import './list_web.css';
import {Col, Row} from "antd";

const ListWebTechnology = (props) => {
    console.log(props.weblist)
    return (
        <>
            <div className='list_web'>
                <div className="block_one_list__technology_new">
                    <div className="title_block_one_list__technology_new">
                        Front-end разработка
                    </div>
                    <div className="list_technology_new">
                        {props.weblist.map(el => (
                            <div className="link_technoology_new">
                                <img src={el.image} alt={el.image}/>
                                {el.name}
                            </div>
                        ))}
                    </div>
                </div>
            </div>
            <div className="list_web">
                <div className="block_one_list__technology_new">
                    <div className="title_block_one_list__technology_new">
                        Back-end разработка
                    </div>
                    <div className="list_technology_new">
                        <div className="link_technoology_new">
                            Node.js
                        </div>
                    </div>
                </div>
            </div>
        </>

    );
};

export default ListWebTechnology;