import './App.css';
import Home from "./components/home/home";
import Header from "./components/header/header";
import {ParallaxProvider} from "react-scroll-parallax";
import React, {useMemo, useState} from "react";
import Footer from "./components/Footer/Footer";
import {Route, Routes} from "react-router-dom";
import AdminAddWorks from "./components/adminAddWorks/adminAddWorks";
import AnimatedDots from "./components/home/animatedDots";
import Contacts from "./components/contacts/contacts";


function App() {



    const [colorBg, setColorBg] = useState('#292929');
    const bgColor = useMemo(() => (typeof colorBg === 'string' ? colorBg : colorBg.toHexString()), [colorBg]);

    const [colorText, setColorText] = useState('#fff');
    const textColor = useMemo(() => (typeof colorText === 'string' ? colorText : colorText.toHexString()), [colorText]);

    const lightTheme = () => {
        if (colorBg === '#292929') {
            setColorBg('#fff');
            setColorText('#000');
        } else {
            setColorBg('#292929');
            setColorText('#fff');
        }
    }


    const devUrl = 'https://it-prodigy.ru'
    // const devUrl = 'http://localhost:5000'




  return (
      <ParallaxProvider>
          <Header
              lightTheme={lightTheme}
              bgColor={bgColor}
              colorBg={colorBg}
              setColorBg={setColorBg}
              colorText={colorText}
              setColorText={setColorText}
              textColor={textColor}
              devUrl={devUrl}
          />

      <Routes>

          <Route path="/admin" element={<AdminAddWorks />} />
          <Route path="/" element={

                  <div className="App" style={{background:`${bgColor}`,transition:'1s all ease'}}>




                      <Home
                          bgColor={bgColor}
                          textColor={textColor}
                          devUrl={devUrl}
                      />

                      <Contacts
                          bgColor={bgColor}
                          textColor={textColor}
                      />

                      <Footer
                          bgColor={bgColor}
                          textColor={textColor}
                      />
                  </div>

          } />
      </Routes>

      </ParallaxProvider>




  );
}

export default App;
