import React, {useEffect, useState} from 'react';
import './header.css';
import { HashLink   } from 'react-router-hash-link';
import {Slide} from "react-awesome-reveal";
import {Link, useNavigate} from "react-router-dom";
import logo from '../../images/icon/logo.svg'
import {Cursor} from "react-creative-cursor";
import ModalSend from "../modal_send/modal_send";
import {UserOutlined } from '@ant-design/icons';
import {Menu} from 'antd';
import ModalAuth from "../modal_auth/modal_auth";
import WelcomeModal from '../welcomeModal/welcomeModal';
import axios from "axios";
import ModalProfile from "../modal_profile/modal_profile";

import BtnButton from "../btn_button_menu/btnButton";




const Header = (props) => {



    const [menu, setMenu] = useState(false); // при наведении на ник показываем меню

    const [linkMenu1, setLinkMenu1] = useState(false); //  hover для профиля
    const [linkMenu2, setLinkMenu2] = useState(false); //  hover для адмн панели
    const [linkMenu3, setLinkMenu3] = useState(false); //  hover для выхода

    const [welcomeVisible, setWelcomeVisible] = useState(false);  //   показ приветственного блока
    const [username, setUsername] = useState('');   //  записываем имя
    const [backupMail, setBackupMail] = useState('') //  почта
    const [phoneUser, setPhone] = useState('') // телефон
    const [nameOrganisation, setNameOrganisation] = useState('') // название организации
    const [isAdmin, setIsAdmin] = useState(0);  // проверяем админ это или нет


    const hoverLinkMenu1Enter = (e) => {
        setLinkMenu1(true)
    }
    const hoverLinkMenu1Leave = () => {
        setLinkMenu1(false)
    }

    const hoverLinkMenu2Enter = (e) => {
        setLinkMenu2(true)
    }
    const hoverLinkMenu2Leave = () => {
        setLinkMenu2(false)
    }

    const hoverLinkMenu3Enter = (e) => {
        setLinkMenu3(true)
    }
    const hoverLinkMenu3Leave = () => {
        setLinkMenu3(false)
    }


    const [isModalOpenAuth, setIsModalOpenAuth] = useState(false);

    const [checkLogin, setCheckLogin] = useState(false)  //  проверка выполнен вход или нет







    const showModalAuth = () => {
        setIsModalOpenAuth(true);
    };






    const [isModalProfileOpen, setIsModalProfileOpen] = useState(false); //  для модалки профиля

    const showModalProfile = () => {
        setIsModalProfileOpen(true);
    };








    const [isModalOpen, setIsModalOpen] = useState(false);

    const showModal = () => {
        setIsModalOpen(true);

    };




    const [headerFixed, setHeaderFixed] = useState(false);
    const [styleMainLink, setStyleMainLink] = useState(false);  //   для смены стиля ссылки main в шапке при прокрутке
    const [styleServicesLink, setStyleServicesLink] = useState(false);  //   для смены стиля ссылки services в шапке при прокрутке
    const [styleAboutLink, setStyleAboutLink] = useState(false);  //   для смены стиля ссылки о нас в шапке при прокрутке
    const [styleStepsWorks, setStyleStepsWorks] = useState(false);  //   для смены стиля ссылки этапы в шапке при прокрутке




        window.addEventListener('scroll' , e => {
            const scrollPosition = window.scrollY;
            const services = document.querySelector('#services');
            const about = document.querySelector('#about');
            const works = document.querySelector('#steps');
            const yPositionServices = services.offsetTop;
            const yPositionAbout = about.offsetTop;
            const yPositionWorks = works.offsetTop;
            if (scrollPosition > 77) {
                setHeaderFixed(true);
            } else {
                setHeaderFixed(false);
            }
            if (scrollPosition > yPositionServices - 70) {
                setStyleServicesLink(true);
                setStyleMainLink(false);  //  убираем активный класс у main сли долистали до services
                setStyleAboutLink(false); //  убираем активный класс у о нас если долистали до services
                setStyleStepsWorks(false); //  убираем активный класс у этапы
                if (scrollPosition > yPositionAbout - 70) {
                    setStyleServicesLink(false);
                    setStyleMainLink(false);  //  убираем активный класс у main сли долистали до services
                    setStyleAboutLink(true);
                    setStyleStepsWorks(false); //  убираем активный класс у этапы
                    if (scrollPosition > yPositionWorks - 70) {
                        setStyleServicesLink(false);
                        setStyleMainLink(false);  //  убираем активный класс у main сли долистали до services
                        setStyleAboutLink(false); //  убираем активный класс у о нас если долистали до services
                        setStyleStepsWorks(true);
                    }
                }
            }
            else {
                setStyleMainLink(true);  //   добавляем активный класс у main сли долистали до services
                setStyleServicesLink(false);
            }

        })


    const [changeCursorLink, setCursor] = useState(20)

    const changeHoverLink = () => {
        setCursor(80)
    }
    const changeHoverLinkOut = () => {
        setCursor(20)
    }

    const navigate = useNavigate();

    useEffect(() => {
        const fetchUserData = async () => {
            const token = localStorage.getItem('token');
            if (!token) {
                console.log('Токен отсутствует, пропуск запроса на получение данных пользователя');
                return;
            }

            try {
                const response = await axios.get(`${props.devUrl}/api/auth/me`, {
                    headers: {
                        'Authorization': `Bearer ${token}`
                    },
                });
                setIsAdmin(response.data.isAdmin);
                setUsername(response.data.username);
                setBackupMail(response.data.backupEmail);
                setPhone(response.data.phone);
                setNameOrganisation(response.data.nameOrganisation);
                setMenu(false);
                console.log(response.data)
            } catch (error) {
                if (error.response && (error.response.status === 401 || error.response.status === 403)) {
                    console.warn('Неправильный или истекший токен. Очистка `localStorage`.');
                    localStorage.removeItem('token');
                }
                console.error('Ошибка получения данных пользователя', error);
            }
        };

        if (checkLogin) {
            fetchUserData();
        }
    }, [checkLogin, username, phoneUser, nameOrganisation]);

    useEffect(() => {
        const token = localStorage.getItem('token');
        if (token) {
            setCheckLogin(true);
        }
    }, []);


    const handleLogout = () => {
        // Удаление токена из локального хранилища
        localStorage.removeItem('token');
        setCheckLogin(false)
    };   //   выход из аккаунта



    return (
        <header style={{
            transition:'1s all ease',

        }} className="header" data-cursor-exclusion>
            <BtnButton
                lightTheme={props.lightTheme}
                textColor={props.textColor}
                setColorText={props.setColorText}
                colorBg={props.colorBg}
                bgColor={props.bgColor}
                setColorBg={props.setColorBg}
                showModal={showModal}/>
            {/*<BtnButtonColor />*/}
            <Cursor
                cursorSize={changeCursorLink}
                animationDuration={.3}
                cursorBackgrounColor={'#fff'}
                isGelly={true}
                gellyAnimationAmount={10}
            />
            {
                headerFixed &&
                <>
                    <div className='header_fixed' style={{transition:'1s all ease'}}>
                        <Slide direction={'down'} duration={500}>
                                <div className="container" style={{
                                    background:`${props.bgColor}`,
                                    borderRadius:'20px',
                                    boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px',
                                }}>
                                    <nav className="menu_header">
                                        <Link

                                            style={{
                                                color:`${props.textColor}`,
                                                transition:'1s all ease',
                                        }}
                                            to={'/'}

                                        >

                                            <span style={{marginRight:'12px'}}>
                                                <img src={logo} alt={logo}/>
                                            </span>
                                            IT Prodigy
                                        </Link>

                                        <ul className="list_menu_header">

                                            <li className={styleMainLink ? 'active_link' : 'item_menu'}>
                                                <HashLink
                                                    style={{
                                                        color:`${styleMainLink === false ? props.textColor : '#ccc'}`,
                                                        borderBottom:`${styleMainLink === true ? '1px solid #ccc' : 'none'}`,
                                                        transition:'1s all ease'
                                                }}

                                                    id="main" smooth to="#top">Главная
                                                </HashLink>
                                            </li>
                                            <li className={styleServicesLink ? 'active_link' : 'item_menu'}>
                                                <HashLink
                                                    style={{
                                                        color:`${styleServicesLink === false ? props.textColor : '#ccc'}`,
                                                        borderBottom:`${styleServicesLink === true ? '1px solid #ccc' : 'none'}`,
                                                        transition:'1s all ease'
                                                }}

                                                    smooth to="#services">Услуги
                                                </HashLink>
                                            </li>
                                            <li className={styleAboutLink ? 'active_link' : 'item_menu'}>
                                                <HashLink
                                                    style={{
                                                        color:`${styleAboutLink === false ? props.textColor : '#ccc'}`,
                                                        borderBottom:`${styleAboutLink === true ? '1px solid #ccc' : 'none'}`,
                                                        transition:'1s all ease'
                                                }}

                                                    smooth to="#about">О нас
                                                </HashLink>
                                            </li>
                                            <li className={styleStepsWorks ? 'active_link' : 'item_menu'}>
                                                <HashLink
                                                    style={{
                                                        color:`${styleStepsWorks === false ? props.textColor : '#ccc'}`,
                                                        borderBottom:`${styleStepsWorks === true ? '1px solid #ccc' : 'none'}`,
                                                        transition:'1s all ease'
                                                }}

                                                    smooth to="#steps">Этапы работы
                                                </HashLink>
                                            </li>
                                            <li className="item_menu">
                                                <HashLink
                                                    style={{color:`${props.textColor}`,transition:'1s all ease'}}

                                                    smooth to="#contacts">Контакты
                                                </HashLink>
                                            </li>
                                            <li className="item_menu d-show-mobile">
                                                <Link
                                                    style={{color:`${props.textColor}`,transition:'1s all ease'}}
                                                    onClick={showModal}

                                                    to={'/'}>
                                                    Let`s Talk
                                                    <span style={{marginLeft:'8px', color:`${props.textColor}`,transition:'1s all ease'}}>
                                                        <svg
                                                            style={{fill:`${props.textColor}`,transition:'1s all ease'}}
                                                            width="12" height="13" viewBox="0 0 12 13" xmlns="http://www.w3.org/2000/svg">
                                                            <path d="M1.51899 11.1859C5.24712 7.06513 5.34235 6.7011 9.53649 2.06521M9.53649 2.06521L3.46405 1.96712M9.53649 2.06521L10.5 7.5" stroke={`${props.textColor}`} stroke-width="2" stroke-linecap="round"/>
                                                        </svg>
                                                    </span>
                                                </Link>
                                            </li>
                                            <li className="item_menu d-show-mobile">
                                                {
                                                    !checkLogin &&
                                                    <Link
                                                        style={{color:`${props.textColor}`,transition:'1s all ease'}}
                                                        onClick={showModalAuth}>
                                                    <span>
                                                        <UserOutlined />
                                                    </span>
                                                    </Link>
                                                }
                                                {
                                                    checkLogin &&
                                                    <div>
                                                        <div
                                                            onMouseEnter={() => {
                                                                setMenu(true)
                                                            }}
                                                            style={{color:`${props.textColor}`,transition:'1s all ease'}}>
                                                            {username}
                                                        </div>
                                                        <Menu
                                                            onMouseLeave={() => {
                                                                setMenu(false)
                                                            }}
                                                            style={{    position: 'absolute',
                                                                right: '-30px',
                                                                zIndex: 9,
                                                                marginTop: '20px',
                                                                background: `${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                                                                borderRadius:'10px',
                                                                display: `${menu === true ? 'block' : 'none'}`,
                                                                cursor:'none',
                                                                color:`${props.textColor}`,
                                                                transition:'1s all ease'
                                                                }}
                                                        >
                                                            {isAdmin && (
                                                                <Menu.Item

                                                                    key="admin" style={{cursor:'none',
                                                                    background:`${linkMenu2 === true ? 'transparent' : `${props.bgColor}`}`,
                                                                    color:`${linkMenu2 === true ? '#292929' : `${props.textColor}`}`,
                                                                    transition:'1s all ease'
                                                                    }}>
                                                                    <Link
                                                                        >Административная панель</Link>
                                                                </Menu.Item>
                                                            )}
                                                            <Menu.Item key="profile"  style={{cursor:'none', display:'flex',justifyContent:'center',
                                                                background:`${linkMenu1 === true ? 'transparent' : `${props.bgColor}`}`,
                                                                color:`${linkMenu1 === true ? '#292929' : `${props.textColor}`}`,transition:'1s all ease'}}>
                                                                <Link
                                                                    style={{color:`${props.textColor}`,transition:'1s all ease'}}

                                                                    onClick={() => {
                                                                        setIsModalProfileOpen(true);
                                                                    }}
                                                                    >
                                                                    Профиль
                                                                </Link>
                                                            </Menu.Item>
                                                            <Menu.Item
                                                                style={{display:'flex',justifyContent:'center',
                                                                    background:`${linkMenu3 === true ? 'transparent' : `${props.bgColor}`}`,
                                                                    color:`${linkMenu3 === true ? '#292929' : `${props.textColor}`}`
                                                                    ,cursor:'none',transition:'1s all ease'
                                                                }}
                                                                key="logout"
                                                                onClick={handleLogout}>
                                                                Выйти
                                                            </Menu.Item>

                                                        </Menu>
                                                    </div>
                                                }
                                            </li>

                                            {/*<li className="item_menu">*/}
                                            {/*    <Link to="/">Main</Link>*/}
                                            {/*</li>*/}
                                            {/*<li className="item_menu">*/}
                                            {/*    <Link to="/uslugi">Services</Link>*/}
                                            {/*</li>*/}
                                            {/*<li className="item_menu">*/}
                                            {/*    <Link to="/portfolio">Our works</Link>*/}
                                            {/*</li>*/}
                                            {/*<li className="item_menu">*/}
                                            {/*    <Link to="/contacts">Contacts</Link>*/}
                                            {/*</li>*/}
                                        </ul>
                                    </nav>
                                </div>
                        </Slide>
                    </div>
                </>
            }
            <div className="container" style={{background:`${props.bgColor}`,
                position: 'absolute',
                margin: '20px auto',
                padding: '0 20px',
                top: '0',
                transform: 'translate(-50%, 0)',
                left: '50%',
                width: 'calc(100% - 80px)',
                zIndex:'9',
                borderRadius:'20px',
                boxShadow: 'rgba(0, 0, 0, 0.35) 0px 5px 15px'
            }}>
                <nav className="menu_header">
                    <Link
                        style={{color:`${props.textColor}`}}

                        to={'/'}>
                            <span style={{marginRight:'12px'}}>
                                <img src={logo} alt={logo}/>
                            </span>
                        IT Prodigy
                    </Link>
                    <ul className="list_menu_header">
                        <li className={styleMainLink ? 'active_link' : 'item_menu'}>
                            <HashLink
                                style={{
                                    color:'#ccc',
                                    borderBottom:'1px solid #ccc',
                            }}

                                smooth to="#main">Главная
                            </HashLink>
                        </li>
                        <li className={styleServicesLink ? 'active_link' : 'item_menu'}>
                            <HashLink
                                style={{color:`${props.textColor}`}}

                                smooth to="#services">Услуги
                            </HashLink>
                        </li>
                        <li className={styleAboutLink ? 'active_link' : 'item_menu'} >
                            <HashLink
                                style={{color:`${props.textColor}`}}
                                smooth to="#about">О нас
                            </HashLink>
                        </li>
                        <li className={styleStepsWorks ? 'active_link' : 'item_menu'} >
                            <HashLink
                                style={{color:`${props.textColor}`}}
                                smooth to="#steps">Этапы работы
                            </HashLink>
                        </li>
                        <li className="item_menu">
                            <HashLink
                                style={{color:`${props.textColor}`}}

                                smooth to="#contacts">Контакты
                            </HashLink>
                        </li>
                        <li className="item_menu d-show-mobile">
                            <Link
                                style={{color:`${props.textColor}`}}
                                onClick={showModal}

                                to={'/'}>
                                Let`s Talk
                                <span style={{marginLeft:'8px', color:`${props.textColor}`}}>
                                    <svg width="12" height="13" viewBox="0 0 12 13" fill="none" xmlns="http://www.w3.org/2000/svg">
                                        <path d="M1.51899 11.1859C5.24712 7.06513 5.34235 6.7011 9.53649 2.06521M9.53649 2.06521L3.46405 1.96712M9.53649 2.06521L10.5 7.5" stroke={`${props.textColor}`} stroke-width="2" stroke-linecap="round"/>
                                    </svg>
                                </span>
                            </Link>
                        </li>
                        <li className="item_menu d-show-mobile" >
                            {
                                !checkLogin &&
                                <Link
                                    style={{color:`${props.textColor}`}}
                                    onClick={showModalAuth}>
                                <span>
                                    <UserOutlined />
                                </span>
                                </Link>
                            }
                            {
                                checkLogin &&
                                <div>
                                    <div
                                        onMouseEnter={() => {
                                            setMenu(true)
                                        }}
                                        style={{color:`${props.textColor}`}}>
                                        {username}
                                    </div>
                                    <Menu
                                        onMouseLeave={() => {
                                            setMenu(false)
                                        }}
                                        style={{   position: 'absolute',
                                            right: '-30px',
                                            zIndex: 9,
                                            marginTop: '20px',
                                            background: `${props.bgColor === '#fff' ? '#292929' : '#fff'}`,
                                            borderRadius:'10px',
                                            display: `${menu === true ? 'block' : 'none'}`,
                                            color:`${props.textColor}`
                                        }}
                                        
                                    >
                                        {isAdmin && (
                                            <Menu.Item
                                                key="admin" style=
                                                    {{cursor:'none',
                                                        background:`${linkMenu2 === true ? 'transparent' : `${props.bgColor}`}`,
                                                        color:`${linkMenu2 === true ? '#292929' : `${props.textColor}`}`
                                            }}
                                            >
                                                <Link
                                                    style={{color:`${props.textColor}`}}
                                                    >Административная панель</Link>
                                            </Menu.Item>
                                        )}
                                        <Menu.Item

                                            key="profile" style={{cursor:'none', display:'flex',justifyContent:'center',
                                            background:`${linkMenu1 === true ? 'transparent' : `${props.bgColor}`}`,
                                            color:`${linkMenu1 === true ? '#292929' : `${props.textColor}`}`
                                        }}>
                                            <Link
                                                onClick={() => {
                                                    setIsModalProfileOpen(true);
                                                }}
                                                style={{color:`${props.textColor}`}}
                                                >Профиль</Link>
                                        </Menu.Item>
                                        <Menu.Item

                                            style={{display:'flex',justifyContent:'center',
                                                background:`${linkMenu3 === true ? 'transparent' : `${props.bgColor}`}`,
                                                color:`${linkMenu3 === true ? '#292929' : `${props.textColor}`}`
                                                ,cursor:'none'
                                            }}
                                            key="logout"
                                            onClick={handleLogout}>
                                                Выйти
                                        </Menu.Item>

                                    </Menu>
                                </div>
                            }
                        </li>

                        {/*<li className="item_menu">*/}
                        {/*    <Link to="/">Main</Link>*/}
                        {/*</li>*/}
                        {/*<li className="item_menu">*/}
                        {/*    <Link to="/uslugi">Services</Link>*/}
                        {/*</li>*/}
                        {/*<li className="item_menu">*/}
                        {/*    <Link to="/portfolio">Our works</Link>*/}
                        {/*</li>*/}
                        {/*<li className="item_menu">*/}
                        {/*    <Link to="/contacts">Contacts</Link>*/}
                        {/*</li>*/}



                    </ul>
                </nav>
            </div>
            <ModalProfile
                isModalProfileOpen={isModalProfileOpen}
                setIsModalProfileOpen={setIsModalProfileOpen}
                backupMail={backupMail}
                phoneUser={phoneUser}
                username={username}
                nameOrganisation={nameOrganisation}
                bgColor={props.bgColor}
                textColor={props.textColor}
                devUrl={props.devUrl}
            />
            <ModalSend
                isModalOpen={isModalOpen}
                setIsModalOpen={setIsModalOpen}
                bgColor={props.bgColor}
                textColor={props.textColor}
                devUrl={props.devUrl}
            />
            <ModalAuth
                setUsername={setUsername}
               setWelcomeVisible={setWelcomeVisible}
               isModalOpenAuth={isModalOpenAuth}
               setIsModalOpenAuth={setIsModalOpenAuth}
               setCheckLogin={setCheckLogin}
                bgColor={props.bgColor}
                textColor={props.textColor}
                devUrl={props.devUrl}
            />
            <WelcomeModal
                visible={welcomeVisible}
                onClose={() => setWelcomeVisible(false)}
                username={username}
                bgColor={props.bgColor}
                textColor={props.textColor}
            />
        </header>
    );
};

export default Header;