import React from 'react';
import { Modal } from 'antd';

const WelcomeModal = ({ visible, onClose, username }, props) => {
    return (
        <Modal
            visible={visible}
            footer={null}
            onCancel={onClose}
            centered
            style={{ backdropFilter: 'blur(5px)', backgroundColor: 'rgba(0, 0, 0, 0.5)', borderRadius:'20px' }}
        >
            <div style={{ textAlign: 'center', color:'#fff' }}>
                <h1>Добро пожаловать, {username}!</h1>
            </div>
        </Modal>
    );
};

export default WelcomeModal;